<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ HeaderTitle }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load()"
        :tooltip="translations.tcCurrentHome"
        :showCamp="false"
        :i18n="translations.components"
      ></camp-selector>
      <div class="page-body container mx-med pt-3">
        <section class="section-3">
          <div class="row">
            <InfoCard
              v-for="(info, index) in campTilesFiltered"
              :key="index"
              :obj="info"
              @set_program="handleSetProgram($event)"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import { countryBreadcrumbMixin } from '@/mixins/breadcrumbMixinFactory'
import InfoCard from '@/components/info-card/InfoCard.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'country-directory',
  mixins: [translationMixin, countryBreadcrumbMixin],
  data() {
    return {
      translations: {},
      HeaderTitle: 'Country Directory',
      campHomeTiles: [
        {
          class: 'col col-6',
          title: '', // Camp Elections
          content: '',
          link: '/officers/directory/country/membership-dinner-speakers',
          label: 'View More', // View More
          img: {
            src: 'svgs/Presenters.svg',
            alt: '', // Camp Elections
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Camp Treasury,
          content: '',
          link: '/officers/directory/country/pastor-banquet-speakers',
          label: '', // View More
          img: {
            src: 'svgs/Presenters.svg',
            alt: '', // Camp Treasury
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Camp Goal Planner
          content: '',
          link: '/officers/directory/country/camp-officer-selections',
          label: '', // View More
          img: {
            src: 'svgs/church-list.svg', //'svgs/church-reports.svg',
            alt: '', // Camp Goal Planner,
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Security Manager
          content: '',
          link: '/officers/directory/country/qualified-speakers',
          label: '', // View More
          img: {
            src: 'svgs/Presenters.svg',
            alt: '', // Security Manager
            width: 64,
            height: 64,
          },
        },

        {
          class: 'col col-6',
          title: '', // Officer Resources
          content: '',
          link: '/officers/directory/country/directory-camp',
          label: '', // View More
          img: {
            src: 'svgs/church-list.svg', //'svgs/program-resources.svg',
            alt: '', // Officer Resources
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Officer Resources
          content: '',
          link: '/officers/directory/country/members-list',
          label: '', // View More
          img: {
            src: 'svgs/church-list.svg',
            alt: '', // Officer Resources
            width: 64,
            height: 64,
          },
        },

        {
          class: 'col col-6',
          title: '', // Officer Resources
          content: '',
          link: '/reports/report-center/cn',
          label: '', // View More
          img: {
            src: 'svgs/church-reports.svg',
            alt: '', // Officer Resources
            width: 64,
            height: 64,
          },
        },
      ],
      defaultTiles: [
        {
          class: 'col col-6',
          title: '', // Validate Membership Applications
          content: '',
          link: '/officers/camp/validate-membership-applications/cm',
          label: '', // View More
          img: {
            src: 'svgs/application.svg',
            alt: '', // Validate Membership Applications
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Comunication Preferences
          content: '',
          link: '/officers/camp/communication-preferences',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: '', // Comunication Preferences
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Camp Profile
          content: '',
          link: '/officers/camp/camp-profile/cm',
          itemId: '1B3A2CD6-63D0-4050-891F-54549C33B7A8', // The sitecore ItemId for ChurchMinistry Program
          label: 'View More',
          img: {
            src: 'yellow-tent-icon.png',
            alt: '', // Camp Profile
            width: 64,
            height: 64,
          },
        },
      ],
    }
  },
  components: {
    campSelector: CampSelect,
    InfoCard: InfoCard,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
    }),
    async page_load() {},
    async handleSetProgram(data) {
      this.setMRPProgramId(data.itemId)
      this.$router.push(data.link)
    },
    loadTranslations() {
      this.HeaderTitle = this.translations.tcCountryDirectoryTitle
      this.campHomeTiles[0].title = this.translations.tcAnnualMemberDinnerSpeakersTitle
      this.campHomeTiles[0].content = this.translations.tcAnnualMemberDinnerSpeakersDescription
      this.campHomeTiles[0].label = this.translations.tcViewMore
      this.campHomeTiles[0].img.alt = this.translations.tcCampElectionsTitle

      this.campHomeTiles[1].title = this.translations.tcAnnualPastorsEventSpeakersTitle
      this.campHomeTiles[1].content = this.translations.tcAnnualPastorsEventSpeakersDescription
      this.campHomeTiles[1].label = this.translations.tcViewMore
      this.campHomeTiles[1].img.alt = this.translations.tcCampTreasuryTitle

      this.campHomeTiles[2].title = this.translations.tcCampOfficersTitle
      this.campHomeTiles[2].content = this.translations.tcCampOfficersDescription
      this.campHomeTiles[2].label = this.translations.tcViewMore
      this.campHomeTiles[2].img.alt = this.translations.tcCampGoalPlannerTitle

      this.campHomeTiles[3].title = this.translations.tcQualifiedChurchSpeakersTitle
      this.campHomeTiles[3].content = this.translations.tcQualifiedChurchSpeakersDescription
      this.campHomeTiles[3].label = this.translations.tcViewMore
      this.campHomeTiles[3].img.alt = this.translations.tcSecurityManagerTitle

      this.campHomeTiles[4].title = this.translations.tcCountryCampIndexTitle
      this.campHomeTiles[4].content = this.translations.tcCountryCampIndexDescription
      this.campHomeTiles[4].label = this.translations.tcViewMore
      this.campHomeTiles[4].img.alt = this.translations.tcOfficerResourcesTitle

      this.campHomeTiles[5].title = this.translations.tcCountryMembersListTitle
      this.campHomeTiles[5].content = this.translations.tcCountryMembersListDescription
      this.campHomeTiles[5].label = this.translations.tcViewMore
      this.campHomeTiles[5].img.alt = this.translations.tcOfficerResourcesTitle

      this.campHomeTiles[6].title = this.translations.tcCountryReportsTitle
      this.campHomeTiles[6].content = this.translations.tcCountryReportsDescription
      this.campHomeTiles[6].label = this.translations.tcViewMore
      this.campHomeTiles[6].img.alt = this.translations.tcOfficerResourcesTitle

      this.defaultTiles[0].title = this.translations.tcValidateMembershipApplicationsTitle
      this.defaultTiles[0].content = this.translations.tcValidateMembershipApplicationsDescription
      this.defaultTiles[0].label = this.translations.tcViewMore
      this.defaultTiles[0].img.alt = this.translations.tcValidateMembershipApplicationsTitle

      this.defaultTiles[1].title = this.translations.tcCommunicationPreferencesTitle
      this.defaultTiles[1].content = this.translations.tcCommunicationPreferencesDescription
      this.defaultTiles[1].label = this.translations.tcViewMore
      this.defaultTiles[1].img.alt = this.translations.tcCommunicationPreferencesTitle

      this.defaultTiles[2].title = this.translations.tcCampProfileTitle
      this.defaultTiles[2].content = this.translations.tcCampProfileDescription
      this.defaultTiles[2].label = this.translations.tcViewMore
      this.defaultTiles[2].img.alt = this.translations.tcCampProfileTitle
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('camp-select', 'security-banner'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      officerSelectStatekey: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userOrgKey: 'user/userOrgKey',
    }),
    allowPage() {
      return true
    },
    campTilesFiltered() {
      return this.campHomeTiles
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.church-ministry {
  .page-body {
    margin-top: 68px;
    @include breakpoint(sm) {
      margin-top: 3rem;
    }
    h4 {
      margin-bottom: 36px;
    }
  }
  .section-1,
  .section-2 {
    margin-bottom: 80px;
    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }
    > .row {
      margin-bottom: 28px;
    }
    .col {
      &:first-of-type {
        padding-right: 23px;
      }
      &:last-of-type {
        padding-left: 23px;
      }
      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
  .section-3 {
    margin-bottom: 60px;
    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }
      &:nth-child(2n) {
        padding-left: 23px;
      }
    }
    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
